var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mx-auto"},[_c('section',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('SearchBar',{staticClass:"mb-3",attrs:{"placeholder":'Search course',"value":_vm.search},on:{"update:value":function($event){_vm.search=$event},"clear":_vm.clearSearch,"search":_vm.onSearch}}),_c('v-select',{staticClass:"custom-border general-custom-field col-xl-3 col-lg-4 roboto f13 secondary-1--text fw500 mb-3",attrs:{"dense":"","solo":"","items":_vm.classes,"item-value":"class_code","hide-details":"","loading":_vm.loadingClasses,"flat":""},on:{"change":_vm.onClassChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-title',{staticClass:"fw500"},[_c('span',[_vm._v(_vm._s(data.item.class_code)+" - ")]),_c('span',{staticClass:"secondary-3--text"},[_vm._v(_vm._s(data.item.class_name))])])]}},{key:"selection",fn:function(data){return [_c('v-list-item-title',{staticClass:"fw500"},[_c('span',[_vm._v(_vm._s(data.item.class_code)+" - ")]),_c('span',{staticClass:"secondary-3--text"},[_vm._v(_vm._s(data.item.class_name))])])]}}]),model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=$$v},expression:"selectedClass"}})],1),_c('v-data-table',{staticClass:"elevation-1 poppins",attrs:{"loading":_vm.loadingCourses,"headers":_vm.instructor_courses_tbl,"items":_vm.courses,"items-per-page":_vm.itemsPerPage,"footer-props":{
            'items-per-page-options': _vm.itemsPerPageOptions
        },"page":_vm.page,"server-items-length":_vm.totalCount},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"pagination":(e) => {
            _vm.page = e.page
            _vm.paginate = String(e.itemsPerPage),
            _vm.selectedClass = _vm.$route.query.classes
            _vm.updateQuery()
        }},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [(item)?_c('span',[_vm._v(_vm._s(item.status)+"ED")]):_vm._e()]}},{key:"item.start",fn:function({ item }){return [(item)?_c('span',[_vm._v(_vm._s(item.available_until?.start_date ? _vm.$dateFormat.mmDDyy(item.available_until.start_date) : ''))]):_vm._e()]}},{key:"item.end",fn:function({ item }){return [(item)?_c('span',[_vm._v(_vm._s(item.available_until?.end_date ? _vm.$dateFormat.mmDDyy(item.available_until.end_date) : ''))]):_vm._e()]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{staticClass:"f12",attrs:{"icon":"","dense":"","color":"primary","text":""},on:{"click":function($event){_vm.$router.push({name: `${_vm.user.role.toLowerCase().replace(/^./, c => c.toUpperCase())} Student Outputs Enrollees`, params: {course_uuid: item.uuid}, query: { search: '', page: 1, paginate: 10 }})}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","dense":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }